import { graphql, useStaticQuery, navigate } from 'gatsby'
import React, { ReactElement, useState, useEffect } from 'react'

import HomebuyingGuideLayout from '../../components/Layout/HomebuyingGuide'
import Hero from '../../components/HomebuyingGuidePage/Hero'
import DestinationsDetails from '../../components/HomebuyingGuidePage/HomebuyingGuideDetails'
import { HOMEBUYING_GUIDE, HomebuyingGuideTypes } from '../../components/HomebuyingGuidePage/data'
import MenuBaseDetails from '../../components/MenuBaseDetails'
import SEO from '../../components/SEO/SEO'

const HomeBuyingGuidePage = ({ pageContext: { data } }: any): ReactElement => {
  const allHomeBuyingGuidePage = useStaticQuery(graphql`
    query allHomeBuyingGuide {
      allSitePage(filter: { context: { category: { eq: "home-buying-guide" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                estate
                description
                title
                updated
                headingTag
                seoTitle
                seoDescription
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const allHomeBuyingGuide = allHomeBuyingGuidePage.allSitePage.edges

  const indexHomeBuyingGuide = (): number => {
    let tempIndex = 0
    allHomeBuyingGuide.map((resHomeBuyingGuide: any, indexHomeBuyingGuide: number) =>
      resHomeBuyingGuide.node.context.data.id === data.id ? (tempIndex = indexHomeBuyingGuide) : null
    )
    return tempIndex
  }

  const [index, setIndex] = useState(indexHomeBuyingGuide)

  const featured = allHomeBuyingGuide.filter((resFeatured: any) => resFeatured.node.context.data.feature === true)
  const indexCount = index + 1
  const prevIndex = index === 0 ? allHomeBuyingGuide.length - 1 : index - 1
  const nextIndex = indexCount === allHomeBuyingGuide.length ? 0 : indexCount

  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  return (
    <>
      <HomebuyingGuideLayout>
        <SEO seoTitle={data.seoTitle} seoDescription={data.seoDescription} title={`${data.type} - ALISI | Ayala Land`} />
        <Hero data={{ context: { data } }} detail={true} />
        <h1 style={{ display: 'none' }}>{data.headingTag}</h1>
        <DestinationsDetails featured={featured} data={data} />
      </HomebuyingGuideLayout>
      <MenuBaseDetails
        title={data.title}
        prevTitle={allHomeBuyingGuide[prevIndex].node.context.data.title}
        prevOnClick={() => navigate(allHomeBuyingGuide[prevIndex].node.path)}
        nextTitle={allHomeBuyingGuide[nextIndex].node.context.data.title}
        nextOnClick={() => navigate(allHomeBuyingGuide[nextIndex].node.path)}
        url={data.slug}
        count={allHomeBuyingGuide.length}
      />
    </>
  )
}

export default HomeBuyingGuidePage
